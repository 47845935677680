import React from 'react';
import { Link } from 'gatsby';

class Slogan extends React.Component {
  render() {
    return (
      <div className="row sep-bottom dark-bg">
        <div className="col-md-8">
          <div className="slogan">
            <h1>
              <img
                src="/design/img/logo.png"
                alt="Attorno logo"
                className="image"
              />
              <small>
                {this.props.heading ||
                  'Mobiele pizzabakkers met authentieke houtovens'}
              </small>
            </h1>
            <Link to="/contacteer-ons" className="btn btn-secondary">
              Contacteer ons
              <i className="icon-paper-plane" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Slogan;
