import React from "react"

import Layout from "../components/layout"
import Nav from "../components/nav"
import Footer from "../components/footer"
import Slogan from "../components/slogan"
import Contact from "../components/contact"
import SEO from "../components/seo"

import "../scss/attorno.scss"

const WaaromAttorno = () => (
  <Layout>
    <SEO
      title="Mobiel pizzakraam"
      description="Wij ontwikkelden een systeem waardoor wij op een uiterst
      gemakkelijke manier onze Italiaanse houtoven tot bij u kunnen
      brengen. Wij monteerden de oven op een platform dat
      ondersteund wordt door luchtbandjes. Deze constructie stelt
      ons in staat om op quasi elke locatie onze oven(s) te
      plaatsen. Een ondergrond van grind, modder, gras deert niet."
    />

    <Nav />

    <header
      className="intro-block py-3 cover-bg"
      style={{ backgroundImage: "url('design/img/header/opgesteld.jpg')" }}
    >
      <div className="container">
        <Slogan heading="Ons mobiel pizzakraam" />
      </div>
    </header>

    <section
      className="pt-8 pb-5 pattern-bg"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <h1 className="mb-1">Ons mobiel pizzakraam</h1>
            <div className="row">
              <div className="col-sm-6">
                <p>
                  Wij ontwikkelden een systeem waardoor wij op een uiterst
                  gemakkelijke manier onze Italiaanse houtoven tot bij u kunnen
                  brengen. Wij monteerden de oven op een platform dat
                  ondersteund wordt door luchtbandjes. Deze constructie stelt
                  ons in staat om op quasi elke locatie onze oven(s) te
                  plaatsen. Een ondergrond van grind, modder, gras deert niet.
                </p>

                <p>
                  Smalle (deur)openingen zijn ook geen probleem omdat wij
                  slechts een breedte van 75 cm nodig hebben. De oven wordt,
                  uiteraard, buiten opgesteld. Een echte{" "}
                  <strong>pizzamobiel</strong> dus. Het onderstel is mooi
                  versierd met steigerhout. Binnenin het onderstel liggen de
                  belegde pizza’s te wachten om afgebakken te worden in de oven.
                  De oven(s) worden geplaatst onder een partytent. Dit creëert
                  echt het effect van een <strong>pizzakraam</strong>. Bij deze
                  constructie wordt de buffettafel bijgeplaatst. Die is ook
                  volledig omgeven met steigerhout, wat een mooi geheel vormt
                  met het <strong>pizzakraam</strong>.
                </p>
              </div>
              <div className="col-sm-6">
                <img
                  src="/design/img/teaser/italiaanse-oven.jpg"
                  alt="Onze houtoven"
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-sm-6">
                <img
                  src="/design/img/teaser/pizzakraam.jpg"
                  alt="Ons mobiel pizzakraam"
                />
              </div>
              <div className="col-sm-6">
                <p>
                  De pizza’s die uit de oven(s) komen, worden op de buffettafel
                  versneden in 6 tot 8 punten. Elk pizzastuk wordt op een
                  kartonnen bordje geplaatst en meegenomen door uw genodigden.
                  Op de tafel bevinden zich ook servetten en verse oliën.
                  Dankzij het feit dat de oven(s) onder tent staan, kan het
                  (regen)weer geen obstakel vormen voor uw feest.
                </p>

                <p>
                  Wat wel kan, is het plaatsen van de buffettafel op een andere
                  (indoor)locatie. Ons personeel brengt dan steeds de pizza’s
                  tot bij de buffettafel. Organiseert u een event met talrijke
                  genodigden, dan is het ook mogelijk om meerdere pizzakramen
                  verspreid op te stellen. De ervaring leert ons dat quasi
                  iedereen de nabijheid van de houtoven(s) enorm apprecieert.
                </p>

                <p>
                  Dankzij het handige transportsysteem van de{" "}
                  <strong>pizzamobiel</strong> (onze oven) en de plooibaarheid
                  van de partytent en buffettafel, zijn wij snel terug
                  vertrokken. De hele opruim duurt niet langer dan een
                  halfuurtje. Wij garanderen u dat niets onze aanwezigheid zal
                  verraden… met uitzondering natuurlijk van het warme gevoel van
                  een zeer geslaagd event.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
)

export default WaaromAttorno
